import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { BaseTablePage } from 'app/shared/classes/view/BaseTablePage';

import { LocaleService } from '@core/services/locale/locale.service';
import { Remediation } from '@shared/classes/model/backend/issue';
import { RemediationPlanMappingService } from '@shared/services/mappings/issue/remediation-plan-mapping.service';
import { AppInjector } from 'app/app-injector';
import {
  ActionTypesEnum,
  ButtonColor,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from 'app/shared/classes';
import { RemediationPlanDataService } from '../../services/data/remediation-plan-data.service';

@Component({
  selector: 'app-remediation-plan-list',
  templateUrl: './remediation-plan-list.component.html',
  styleUrls: ['./remediation-plan-list.component.scss'],
})
export class RemediationPlanListComponent extends BaseTablePage<Remediation> implements OnInit {
  constructor(
    private requestService: RemediationPlanDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.Remediation,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      localeService
    );
    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
            ),
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
            ),
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label:
            this.localeService.translate('general.actions.add') +
            ' ' +
            this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
            ),
          id: 1,
          type: ActionTypesEnum.Add,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void {}

  setCols() {
    const mappingService = AppInjector.get(RemediationPlanMappingService);
    this.cols = [...mappingService.tableFields]
      .map((x) => {
        return {
          ...x,
          name: this.localeService.translate(
            `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}.label`,
            x.name
          ),
          translationKeyPrefix:
            x.translationKeyPrefix ||
            `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}`,
        };
      })
      .map((x) => {
        return {
          ...x,
          name: this.localeService.translate(
            `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.fields.${x.key}.label`,
            x.name
          ),
        };
      });
  }
}
