import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  AggregateType,
  ApiResponseRemediationEvent,
  BadgeColor,
  DataTypeEnum,
  IAction,
  IColumn,
  ITableLoadEvent,
  IssueDto,
  ItemBasePage,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  OfflineConfig,
  RecordStatusEnumFilterWithoutDeleted,
  TargetTypeEnum,
  humanizeCasedString,
  makePlural,
  parseAggregateData,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { Remediation } from '@shared/classes/model/backend/issue';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { combineLatest, takeUntil } from 'rxjs';
import { IssuesDataService } from '../../services/data/issues-data.service';
import { RemediationPlanDataService } from '../../services/data/remediation-plan-data.service';
import { RemediationChangeStatusFormComponent } from '../remediation-change-status-form/remediation-change-status-form.component';

@Component({
  selector: 'app-remediation-plan-item',
  templateUrl: './remediation-plan-item.component.html',
  styleUrls: ['./remediation-plan-item.component.scss'],
})
export class RemediationPlanItemComponent extends ItemBasePage<Remediation> {
  badgeColors = BadgeColor;
  chartData: any = {
    [IssueDto.IssueStatusEnum.Analysis]: 0,
    [IssueDto.IssueStatusEnum.Draft]: 0,
    [IssueDto.IssueStatusEnum.ClosedResolved]: 0,
    [IssueDto.IssueStatusEnum.Remediation]: 0,
    [IssueDto.IssueStatusEnum.ClosedUnresolved]: 0,
  };

  cdata: any;

  options: any;

  toDraftAction: IAction = {
    id: 1,
    label: 'Return to Draft',
    buttonType: 'button',
    color: 'secondary',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Draft),
    icon: 'fas fa-clock-rotate-left',
  };

  toSubmittedAction: IAction = {
    id: 2,
    label: 'Submit',
    buttonType: 'button',
    color: 'info',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Submitted),
    icon: 'fas fa-user-clock',
  };
  // toApprovedAction: IAction = {
  //   id: 3,
  //   label: 'Approved',
  //   buttonType: 'button',
  //   command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Approved),
  //   icon: 'fas fa-check-double',
  //   color: 'success',
  // };

  approveAction: IAction = {
    id: 5,
    label: 'Approve',
    buttonType: 'button',
    command: this.onApproveEvent.bind(this),
    icon: 'pi pi-check',
    color: 'success',
  };

  toCompletedAction: IAction = {
    id: 4,
    label: 'Completed',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Completed),
    icon: 'pi pi-check',
    color: 'danger',
  };
  toStartedAction: IAction = {
    id: 5,
    label: 'Start',
    buttonType: 'button',
    color: 'warning',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Started),
    icon: 'pi pi-send',
  };
  get actionsMap() {
    return {
      // [ApiResponseRemediationEvent.DataEnum.Approved]: this.toApprovedAction,
      ['APPROVE']: this.approveAction,
      [ApiResponseRemediationEvent.DataEnum.Completed]: this.toCompletedAction,
      [ApiResponseRemediationEvent.DataEnum.Draft]: this.toDraftAction,
      [ApiResponseRemediationEvent.DataEnum.Started]: this.toStartedAction,
      [ApiResponseRemediationEvent.DataEnum.Submitted]: this.toSubmittedAction,
    };
  }
  get statusChangeValue() {
    return {
      // [ApiResponseRemediationEvent.DataEnum.Approved]: Remediation.StatusEnum.Approved,
      [ApiResponseRemediationEvent.DataEnum.Completed]: Remediation.StatusEnum.Completed,
      [ApiResponseRemediationEvent.DataEnum.Draft]: Remediation.StatusEnum.Draft,
      [ApiResponseRemediationEvent.DataEnum.Started]: Remediation.StatusEnum.Started,
      [ApiResponseRemediationEvent.DataEnum.Submitted]: Remediation.StatusEnum.Submitted,
    };
  }
  onChangeStatus(status: ApiResponseRemediationEvent.DataEnum) {
    if (status == 'TO_COMPLETED') {
      const popupFormData = {};
      this.data?.issues?.forEach((issue) => {
        popupFormData[issue] = IssueDto.IssueStatusEnum.ClosedResolved;
      });
      this.appDialogService.showDialog(
        BaseFormPopupComponent,
        'Change Status',
        (data) => {
          if (data) {
            this.changeStatusRequest(status, { messages: data });
          }
        },
        {
          width: '50%',
          data: {
            dynamicViewComponent: RemediationChangeStatusFormComponent,
            dataService: this.requestService,
            filters: [],
            selectedRows: [],
            patchData: false,
            formData: { CLOSE_ISSUES: popupFormData },
          },
        }
      );
    } else {
      this.changeStatusRequest(status);
    }
  }
  changeStatusRequest(status: ApiResponseRemediationEvent.DataEnum, body = {}) {
    this.requestService.changeStatus(this.itemId, status, body).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.data.status = this.statusChangeValue[status];
      this.getItemData(this.itemId);
      this.refreshRules();
    });
  }
  cols: IColumn[] = [];
  offlineConfig: OfflineConfig = {
    lazy: true,
    paginator: true,
    showActionBar: false,
  };
  statusTotal: number = 0;
  linkedIssues: IssueDto[] = [];
  linkedIssuesTableEvent: ITableLoadEvent = new ITableLoadEvent();
  constructor(
    public requestService: RemediationPlanDataService,
    public issuesRequestService: IssuesDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private appDialogService: AppDialogService,
    public localeService: LocaleService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.Remediation,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate(
                      `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                    ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Remediation,
        });
      });
    this.initIssueCols();
  }
  onSetData(): void {
    this.fetchIssues(null);
    this.subs.sink = this.issuesRequestService
      .aggregate({
        aggregationFields: [
          {
            operation: 'COUNT',
            fieldName: 'issueStatus',
          },
        ],
        groupByFields: [{ fieldName: 'issueStatus', typeShape: 'NORMAL' }],
        filters: [{ property: 'code', operation: 'IN', value: this.data.issues }],
      })
      .subscribe((res) => {
        this.statusTotal = 0;
        // this.chartData = {
        //     [IssueDto.IssueStatusEnum.Analysis]: 0,
        //     [IssueDto.IssueStatusEnum.Draft]: 0,
        //     [IssueDto.IssueStatusEnum.ClosedResolved]: 0,
        //     [IssueDto.IssueStatusEnum.Remediation]: 0,
        //     [IssueDto.IssueStatusEnum.ClosedUnresolved]: 0
        // };
        // this.linkedIssues?.forEach((el) => {
        //     this.chartData[el.issueStatus]++;
        // });
        this.chartData = parseAggregateData(
          res.aggregation_value,
          'issueStatus',
          AggregateType.Count,
          IssueDto.IssueStatusEnum
        );
        Object.keys(this.chartData).forEach((element) => {
          this.statusTotal += this.chartData[element];
        });

        this.addChartData();
      });
  }

  fetchIssues(tableEvent) {
    this.subs.sink = this.issuesRequestService
      .search(
        { ...this.linkedIssuesTableEvent.pageInfo.pagination },
        { filters: [{ property: 'code', operation: 'IN', value: this.data.issues }] },
        { showLoading: false, showMsg: false }
      )
      .subscribe((res) => {
        this.linkedIssues = res.content as any[];
      });
  }

  addChartData() {
    const documentStyle = getComputedStyle(document.documentElement);

    const textColor = documentStyle.getPropertyValue('--text-color');

    this.cdata = {
      labels: Object.keys(this.chartData).map((x) => humanizeCasedString(x)), //["Draft", "Analysis", "Closed Resolved", "Closed Un resolved", "Remediation"],
      datasets: [
        {
          data: Object.keys(this.chartData).map((x) => this.chartData[x]),
          backgroundColor: Object.keys(this.chartData).map((x) => this.badgeColors[x]),
          hoverBackgroundColor: Object.keys(this.chartData).map((x) => this.badgeColors[x]),
        },
      ],
    };

    this.options = {
      borderColor: 'transparent',
      cutout: '60%',
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
    };
  }

  initIssueCols() {
    this.cols = [
      {
        name: 'Code',
        key: 'code',
        dataType: DataTypeEnum.CodeWithStatus,
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Record Status',
        key: 'recordStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Criticality',
        key: 'criticality',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.CriticalityEnum,
        },
      },
      {
        name: 'Owner',
        key: 'issueOwner',
        dataType: DataTypeEnum.ResponsibilityListView,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Responsibility,
        },
      },
      {
        name: 'Status',
        key: 'issueStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.IssueStatusEnum,
        },
      },
      {
        name: 'Assignee ',
        key: 'assignee',
        dataType: DataTypeEnum.ResponsibilityListView,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Responsibility,
        },
      },
      {
        name: 'Creator Name',
        key: 'creatorName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
    ];
  }

  onApproveEvent() {
    this.requestService.getApprove(this.data?.id).subscribe((red) => {
      this.requestService.navigateToListPage();
    });
  }
}
